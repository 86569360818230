@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,400i,600,600i');
@import url('https://fonts.googleapis.com/css?family=VT323');

@import "global/main";
@import "global/variables";

ul {
  margin: 0; }

#custom-nav {
  padding: 20px 5%;

  .column:nth-child(2) {
    display: flex;
    flex-direction: row;
    align-items: flex-end; } }

.is-200x200 {
  $img-height: 200px;
  width: $img-height;
  height: $img-height; }

.flex-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center; }

.margin-bottom-large {
  margin-bottom: 40px; }

section.is-primary {
  background-color: $primary;

  & h1, h2, h3, h4, h5, h6, p, span {
    color: white; } }

.container.is-narrow {
  max-width: 1000px; }

section.skills {
  margin-top: -14rem;

  .column:not(:last-of-type) {
    border-right: 1px solid transparentize($grey-light, 0.8); }

  figure.image {
    margin-bottom: 10px;

    img {
      width: 48px; } }

  .list-title {
    margin: 50px 0 10px 0; } }

.column {
  padding: 1.5rem; }

ul.is-unstyled {
  list-style: none; }

.projectThumb {
  width: 200px; }

.project-column {
  height: 200px;

  .box {
    height: 100%;
    display: flex;
    align-items: center;
    justify-items: center; } }
